<template>
    <div>
        <v-layout row justify-center>
            <v-dialog v-model="dialog" persistent max-width="490">
                <v-card v-if="person" >
                    <v-card-title class="grey lighten-2">Möchten Sie den Eintrag wirklich löschen?</v-card-title>
                    <v-card-text class="mt-1">Den Eintrag <span class="font-orange">{{person.anrede}}&nbsp;{{ person.vorname}}&nbsp;{{ person.nachname}}</span> unwiederuflich löschen?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                      <v-btn color="primary" outlined dark @click="updateDialog(false); deltePerson(); ">JA</v-btn>
                        <v-btn color="black" dark @click="updateDialog(false)">Abbrechen</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    export default {
        data() {
            return {
                personid: null

            }
        },
        watch: {

        },
        beforeMount() {

        },
        computed: {
            ...mapState(['personen', 'filteredPersonen', 'papierkorb'])
        },
        props: ['dialog', 'accept', 'person'],
        mounted() {
        },
        methods: {
            deltePerson: function () {
                this.$http.post('/api/Stammdaten/DeletePerson', this.person).then(response => {
                  const person = this.personen.find(p => p.id === this.person.id);
                  this.$store.dispatch('addPersonPapierkorb', person)
                  this.$store.dispatch('removePerson', this.person)
                  this.$emit('filter');
                }).catch((error) => console.log(error));

            },
            updateDialog: function (value) {
                this.$emit('update:dialog', value)
            },
            acceptDialog: function () {
                this.$emit('update:dialog', false);
                this.$emit('update:accept', true);
            },
            deniedDialog: function (value) {
                this.$emit('update:dialog', false);
                this.$emit('update:accept', true);
            },
        },

    }
</script>

<style scoped>
</style>
